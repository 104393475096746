import { render, staticRenderFns } from "./_profileId.vue?vue&type=template&id=1880b8a0&scoped=true&"
import script from "./_profileId.vue?vue&type=script&lang=js&"
export * from "./_profileId.vue?vue&type=script&lang=js&"
import style0 from "./_profileId.vue?vue&type=style&index=0&id=1880b8a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1880b8a0",
  null
  
)

export default component.exports